import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Buyer from './Buyer';
import Seller from './Seller';
import WebApp from './WebApp';
import CommunityApp from './CommunityApp';


import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Router } from "react-router-dom";
import { createBrowserHistory } from "history";

//import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/custom.css';
import './assets/css/custom_2.css';
import './assets/css/style2.css';
import './assets/css/webapp.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-rater/lib/react-rater.css';
import './assets/css/responsive.css';
import { buyerUrl, webMobileUrl, buyerHost, sellerHost, webMobileHost, communityHost } from './config';
import { uToken } from './useToken';
const history = createBrowserHistory();
// begin user token
  const getUrlAuthToken = () => {
    const paramsMatch = window.location.href.match(/\?.+/);
    
    if (paramsMatch) {
        const params = new URLSearchParams(paramsMatch[0]);
        const newUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, "", newUrl);
    } 
    return true;
  };
  
// end user token

var localpage = ["/", "/listing", "/biz", "/listing-gallery", "/maps"];

let token = localStorage.getItem('token');
if(token != '' && token != null){
  getUrlAuthToken();
}
ReactDOM.render(
  <React.Fragment>
    <Router history={history}>
      {(navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/WebOs/i)) ?
        <>
          {localpage.includes(window.location.pathname) == true && (window.location.host != sellerHost && window.location.host != communityHost) ?
            <>
              {window.location.host != webMobileHost ?
                token != 'null' && token != null ?
                  window.location.replace(webMobileUrl + '?s=' + encodeURI(token))
                  : window.location.replace(webMobileUrl)
                : console.log('here1')}
            </>
            : ""
          }
        </>
        :
        window.location.host != buyerHost && window.location.host != communityHost && window.location.host != sellerHost ? window.location.replace(buyerUrl) : console.log('here')

      }
      {window.location.host === buyerHost ?
        <Buyer />
        : window.location.host === sellerHost ?
          <Seller />
          : window.location.host === webMobileHost ?
            <WebApp />
            : window.location.host === communityHost ?
              <CommunityApp />
              : ''
      }
    </Router>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
