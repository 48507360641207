export const  ApiUrl = "https://admin.katika.us/api/";
export const buyerUrl = 'https://user.katika.us';
export const sellerUrl = 'https://biz.katika.us';
export const webMobileUrl = 'https://m.katika.us';
export const communityUrl = 'https://community.katika.us';

export const adminUrl = 'https://katika.us';
export const frontLaravelUrl = 'https://katika.us';
export const buyerHost = 'user.katika.us';
export const sellerHost = 'biz.katika.us';
export const webMobileHost = 'm.katika.us';
export const communityHost = 'community.katika.us';

export const FbId  = "3561836810565991";
export const FbSecertKey  = "57d2b06116fa541596b7c656926b4fc0";
export const AutoApiKey = "AIzaSyDQy9sCLvDj2anzl8152anNw-nXC3rBpik"
export const algoliaAppId = '2OOTPE0XV0';
export const algoliaAppKeyId = 'e4aec6f32bceda0ce7815cb64c2d7b9e';
export const PusherKey = '4a214d4143e5c22db7db';

export const PlaystoreUrl = 'https://play.google.com/store/apps/details?id=com.katikabuyer';
export const AppstoreURL = 'https://apps.apple.com/us/app/katika/id1595089103';
////////////////////////////////////////LIVE//////////////////////////////////////////////////////
export const GoogleClientId = "1010701749545-ii29e2p2sdq2u8941cgkripi13ch3gbj.apps.googleusercontent.com";
export const GoogleSecertKey = "-S6WQ5i0YRFdUHdbm64M1Q5g";
export const CaptchaKey ="6LdsVl0aAAAAALaSWE2iAeWHB3Vr4cq1mThRQE0-";


export const StripePublishableKey = "pk_live_51KHME4LrXbTXx8uiT6G8QaP47lUcnTayiLTmpaumNo5TJRylzoxCfxBjByPOUw2FgCLZbvDtzkhteOEud5gMVSzm00d9JNhFJ5";
export const StripeSecretKey = "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";
export const MixpanelToken = '78f2addaf6b7e32db29cf249a0065b26';
//////////////////////////////////////////LIVE////////////////////////////////////////////////


//export const GoogleClientId = "1010701749545-80idcchmk2p3og39ghjgfp0jhpi6av44.apps.googleusercontent.com";
//export const GoogleSecertKey = "-S6WQ5i0YRFdUHdbm64M1Q5g";

//export const CaptchaKey ="6LeO9SMaAAAAAN-v6DC053L8jl_piV4pJWJWylEU";
